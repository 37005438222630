import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as SecureStorage from 'react-secure-storage';
import projectPackage from '../../../package.json';
import { Input, Button } from '../../components';
import { ADMINISTRATIVE_DASHBOARD, SALES_MANAGEMENT } from '../../routes';
import { useDispatch, fetchLogin, useSelector } from '../../stores';
import { palette, logo } from '../../theme';
import { handleErrors } from '../../utils/Helpers';
import {
  StyledWrapper,
  StyledMainBox,
  StyledBodyContainer,
  StyledBodyBox,
  StyledLogo,
  StyledLogoWrapper,
  StyledButtonsBox,
  StyledFormGroup,
} from './styles';
import validationSchema from './validations';

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { authLoading } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (
      SecureStorage.default.getItem('id') !== null &&
      SecureStorage.default.getItem('token') !== null
    ) {
      navigate(ADMINISTRATIVE_DASHBOARD);
    }
  }, []);

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchLogin({
            username: formData.username,
            password: formData.password,
          }),
        ).then((res: any) => {
          if (res.meta.requestStatus === 'fulfilled') {
            if (res.payload.data.profileId !== 9) {
              navigate(ADMINISTRATIVE_DASHBOARD);
            } else {
              navigate(SALES_MANAGEMENT);
            }
          }
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReset = () => {
    setFormData({
      username: '',
      password: '',
    });
  };

  return (
    <StyledWrapper>
      <Grid>
        <StyledMainBox boxShadow={20}>
          <StyledLogoWrapper>
            <StyledLogo src={logo} alt='logo' />
          </StyledLogoWrapper>
          <StyledBodyContainer>
            <StyledBodyBox>
              <StyledFormGroup onSubmit={handleSubmit}>
                <Input
                  data-testid='username'
                  type='text'
                  margin='normal'
                  label='Login'
                  value={formData.username}
                  onChange={(value) =>
                    setFormData({ ...formData, username: value })
                  }
                  errorMessage={errors.username}
                />
                <Input
                  data-testid='password'
                  type={showPassword ? 'text' : 'password'}
                  label='Senha'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData.password}
                  onChange={(InputProps) =>
                    setFormData({ ...formData, password: InputProps })
                  }
                  errorMessage={errors && errors.password}
                />
                <StyledButtonsBox>
                  <Button
                    data-testid='submit'
                    variant='contained'
                    label='Entrar'
                    loading={authLoading}
                    style={{ width: '100%' }}
                    onClick={handleSubmit}
                    type='submit'
                  />
                  <Button
                    data-testid='clean'
                    variant='outlined'
                    label='Limpar'
                    style={{ width: '100%' }}
                    onClick={handleReset}
                  />
                </StyledButtonsBox>
              </StyledFormGroup>
            </StyledBodyBox>
          </StyledBodyContainer>
        </StyledMainBox>
        <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography padding={5} color={palette.primary.contrastText}>
            Versão {projectPackage.version}
          </Typography>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};
