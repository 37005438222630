import { useWorker } from '@koale/useworker';
import DownloadIcon from '@mui/icons-material/Download';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { Button, SplitButton } from '../../../components';
import {
  useDispatch,
  useSelector,
  salesListSlice,
  fetchSalesExportExcel,
  fetchSalesExportCommissionsTxt,
  fetchSalesExportCommissionsHtml,
  fetchSalesExportSalesTxt,
  fetchSalesExportSalesHtml,
} from '../../../stores';
import { handleMoney } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledTableHeader } from '../styles';
import {
  exportSalesToCSV,
  exportSalesCommissionsToTXT,
  exportSalesCommissionsToHTML,
  exportSalesToTXT,
  exportSalesToHTML,
  handleApiDataSalesToCSV,
  handleApiDataSalesCommissionsToTXTPartOne,
  handleApiDataSalesCommissionsToTXTPartTwo,
  handleApiDataSalesCommissionsToTXTPartThree,
  handleApiDataSalesCommissionsToHTMLPartOne,
  handleApiDataSalesCommissionsToHTMLPartTwo,
  handleApiDataSalesCommissionsToHTMLPartThree,
  handleApiDataSalesToHTMLPartOne,
  handleApiDataSalesToHTMLPartTwo,
  handleApiDataSalesToHTMLPartThree,
  handleApiDataSalesToHTMLPartFour,
  handleApiDataSalesToTXT,
  handleApiDataSalesToTXTNew,
  exportSalesToTXTNew,
} from './exports';

export const VehiclesEntryTableToolbar = () => {
  const dispatch = useDispatch();
  const {
    salesList,
    salesExportExcelLoading,
    salesExportCommissionsTxtLoading,
    salesExportCommissionsHtmlLoading,
    salesExportSalesTxtLoading,
    salesExportSalesHtmlLoading,
    salesExportOperationalHtmlLoading,
  } = useSelector((state) => state.salesListReducer);
  const salesFiltersValues = useSelector(
    (state) => state.salesFiltersValuesReducer,
  );
  const { profileId } = useSelector((state) => state.authReducer);
  const responsiveMode = useResponsiveMode();
  const [exportSalesToCSVWorker] = useWorker(handleApiDataSalesToCSV);
  const [exportSalesCommissionsToTXTPartOneWorker] = useWorker(
    handleApiDataSalesCommissionsToTXTPartOne,
  );
  const [exportSalesCommissionsToTXTPartTwoWorker] = useWorker(
    handleApiDataSalesCommissionsToTXTPartTwo,
  );
  const [exportSalesCommissionsToTXTPartThreeWorker] = useWorker(
    handleApiDataSalesCommissionsToTXTPartThree,
  );
  const [exportSalesCommissionsToHTMLPartOneWorker] = useWorker(
    handleApiDataSalesCommissionsToHTMLPartOne,
  );
  const [exportSalesCommissionsToHTMLPartTwoWorker] = useWorker(
    handleApiDataSalesCommissionsToHTMLPartTwo,
  );
  const [exportSalesCommissionsToHTMLPartThreeWorker] = useWorker(
    handleApiDataSalesCommissionsToHTMLPartThree,
  );
  const [exportSalesToHTMLPartOneWorker] = useWorker(
    handleApiDataSalesToHTMLPartOne,
  );
  const [exportSalesToHTMLPartTwoWorker] = useWorker(
    handleApiDataSalesToHTMLPartTwo,
  );
  const [exportSalesToHTMLPartThreeWorker] = useWorker(
    handleApiDataSalesToHTMLPartThree,
  );
  const [exportSalesToHTMLPartFourWorker] = useWorker(
    handleApiDataSalesToHTMLPartFour,
  );
  const [exportSalesToTXTWorker] = useWorker(handleApiDataSalesToTXT);
  const [exportSalesToTXTNewWorker] = useWorker(handleApiDataSalesToTXTNew);

  const handleExportExcel = () => {
    dispatch(fetchSalesExportExcel(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesToCSV(
            res.payload.data,
            res.payload.filters,
            dispatch,
            exportSalesToCSVWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(salesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportCommissionsTxt = () => {
    dispatch(fetchSalesExportCommissionsTxt(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesCommissionsToTXT(
            res.payload.data,
            dispatch,
            exportSalesCommissionsToTXTPartOneWorker,
            exportSalesCommissionsToTXTPartTwoWorker,
            exportSalesCommissionsToTXTPartThreeWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(salesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportCommissionsHtml = () => {
    dispatch(fetchSalesExportCommissionsHtml(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesCommissionsToHTML(
            res.payload.data,
            res.payload.startDate,
            res.payload.finalDate,
            dispatch,
            exportSalesCommissionsToHTMLPartOneWorker,
            exportSalesCommissionsToHTMLPartTwoWorker,
            exportSalesCommissionsToHTMLPartThreeWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(salesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportSalesTxt = () => {
    dispatch(fetchSalesExportSalesTxt(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesToTXT(
            res.payload.data,
            res.payload.filters.routes,
            dispatch,
            exportSalesToTXTWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(salesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportSalesTxtNew = () => {
    dispatch(fetchSalesExportSalesTxt(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesToTXTNew(
            res.payload.data,
            res.payload.filters.routes,
            dispatch,
            exportSalesToTXTNewWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(salesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportSalesHtml = () => {
    dispatch(fetchSalesExportSalesHtml(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesToHTML(
            res.payload.data,
            res.payload.filters.users,
            res.payload.filters.areas,
            res.payload.filters.routes,
            dispatch,
            exportSalesToHTMLPartOneWorker,
            exportSalesToHTMLPartTwoWorker,
            exportSalesToHTMLPartThreeWorker,
            exportSalesToHTMLPartFourWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(salesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportOperationalHtml = () => {
    dispatch(salesListSlice.actions.toggleSalesOperationalExportIsOpen());
  };

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        {responsiveMode !== 'phone' && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {profileId !== 9 && (
              <>
                <Button
                  variant='contained'
                  label='Exportar em excel'
                  startIcon={<DownloadIcon />}
                  onClick={() => handleExportExcel()}
                  loading={salesExportExcelLoading}
                />
                <SplitButton
                  label='Exportar em HTML'
                  startIcon={<DownloadIcon />}
                  loading={
                    salesExportCommissionsHtmlLoading ||
                    salesExportSalesHtmlLoading ||
                    salesExportOperationalHtmlLoading
                  }
                  options={[
                    {
                      label: 'Relatório de comissões (declaração)',
                      onClick: handleExportCommissionsHtml,
                    },
                    {
                      label: 'Relatório de vendas resumidas',
                      onClick: handleExportSalesHtml,
                    },
                    {
                      label: 'Relatório de apoio operacional',
                      onClick: handleExportOperationalHtml,
                    },
                  ]}
                />

                <SplitButton
                  label='Exportar em TXT'
                  startIcon={<DownloadIcon />}
                  loading={
                    salesExportCommissionsTxtLoading ||
                    salesExportSalesTxtLoading
                  }
                  options={[
                    {
                      label: 'Relatório de comissões (Globus)',
                      onClick: handleExportCommissionsTxt,
                    },
                    {
                      label: 'Relatório de vendas (Globus)',
                      onClick: handleExportSalesTxt,
                    },
                    {
                      label: 'Novo relatório de vendas (Globus)',
                      icon: (
                        <NewReleasesIcon
                          color='success'
                          sx={{ marginLeft: 1 }}
                        />
                      ),
                      onClick: handleExportSalesTxtNew,
                    },
                  ]}
                />
              </>
            )}
          </div>
        )}
        <Typography margin={1} sx={{ textAlign: 'right', paddingRight: 2 }}>
          Qtd.: {salesList?.size}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total:{' '}
          {handleMoney(salesList?.totalAmount)}
        </Typography>
      </div>
    </StyledTableHeader>
  );
};
