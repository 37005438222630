import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import * as SecureStorage from 'react-secure-storage';
import {
  ActionsHistory,
  AdministrativeDashboard,
  AreasManagement,
  ConfigurationUpsert,
  ConfigurationsManagement,
  Login,
  NotFound,
  NotificationsManagement,
  OperationalDashboard,
  PrivateSalesManagement,
  Profile,
  RouteUpsert,
  RoutesManagement,
  SalesManagement,
  SessionsHistory,
  UserRoutes,
  UserUpsert,
  UsersManagement,
  CredentialsManagement,
  CredentialsUpsert,
  PermissionsManagement,
} from '../pages';
import { NotificationEditRecords } from '../pages/NotificationsManagement/NotificationEditRecords';
import {
  ACTIONS_HISTORY,
  ADMINISTRATIVE_DASHBOARD,
  AREAS_MANAGEMENT,
  CONFIGURATION_CREATE,
  CONFIGURATION_UPDATE,
  CONFIGURATIONS_MANAGEMENT,
  CREDENTIALS_CREATE,
  CREDENTIALS_MANAGEMENT,
  CREDENTIALS_UPDATE,
  LOGIN,
  NOTIFICATIONS_MANAGEMENT,
  NOTIFICATIONS_EDIT_RECORDS,
  OPERATIONAL_DASHBOARD,
  PERMISSIONS_MANAGEMENT,
  PRIVATE_MANAGEMENT,
  PROFILE,
  ROUTES_MANAGEMENT,
  ROUTE_CREATE,
  ROUTE_UPDATE,
  SALES_MANAGEMENT,
  SESSIONS_HISTORY,
  USER_CREATE,
  USER_ROUTES,
  USER_UPDATE,
  USERS_MANAGEMENT,
} from '../routes';
import ProtectedRoute from './components/ProtectedRoute';

function RequireAuth({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();

  const logout = () => {
    if (
      SecureStorage.default.getItem('id') === null ||
      SecureStorage.default.getItem('token') === null
    ) {
      window.removeEventListener('storage', logout);
      navigate(LOGIN);
    }
  };

  window.addEventListener('storage', logout);

  if (
    SecureStorage.default.getItem('id') === null ||
    SecureStorage.default.getItem('token') === null
  ) {
    navigate(LOGIN);
  }

  return children;
}

export const RouterConfig: React.FC = () => {
  return (
    <Router>
      {/* <AuthProvider> */}
      <Routes>
        <Route index element={<Login />} />

        <Route
          path={SALES_MANAGEMENT}
          element={
            <RequireAuth>
              <SalesManagement />
            </RequireAuth>
          }
        />

        <Route element={<ProtectedRoute allowedPage='/vendas' />}>
          <Route
            path={ADMINISTRATIVE_DASHBOARD}
            element={
              <RequireAuth>
                <AdministrativeDashboard />
              </RequireAuth>
            }
          />
          <Route
            path={AREAS_MANAGEMENT}
            element={
              <RequireAuth>
                <AreasManagement />
              </RequireAuth>
            }
          />
          <Route
            path={CONFIGURATIONS_MANAGEMENT}
            element={
              <RequireAuth>
                <ConfigurationsManagement />
              </RequireAuth>
            }
          />
          <Route
            path={CONFIGURATION_CREATE}
            element={
              <RequireAuth>
                <ConfigurationUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={CONFIGURATION_UPDATE}
            element={
              <RequireAuth>
                <ConfigurationUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={CREDENTIALS_MANAGEMENT}
            element={
              <RequireAuth>
                <CredentialsManagement />
              </RequireAuth>
            }
          />
          <Route
            path={CREDENTIALS_CREATE}
            element={
              <RequireAuth>
                <CredentialsUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={CREDENTIALS_UPDATE}
            element={
              <RequireAuth>
                <CredentialsUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={NOTIFICATIONS_MANAGEMENT}
            element={
              <RequireAuth>
                <NotificationsManagement />
              </RequireAuth>
            }
          />
          <Route
            path={NOTIFICATIONS_EDIT_RECORDS}
            element={
              <RequireAuth>
                <NotificationEditRecords />
              </RequireAuth>
            }
          />
          <Route
            path={OPERATIONAL_DASHBOARD}
            element={
              <RequireAuth>
                <OperationalDashboard />
              </RequireAuth>
            }
          />
          <Route
            path={PRIVATE_MANAGEMENT}
            element={
              <RequireAuth>
                <PrivateSalesManagement />
              </RequireAuth>
            }
          />
          <Route
            path={PROFILE}
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path={PERMISSIONS_MANAGEMENT}
            element={
              <RequireAuth>
                <PermissionsManagement />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES_MANAGEMENT}
            element={
              <RequireAuth>
                <RoutesManagement />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTE_CREATE}
            element={
              <RequireAuth>
                <RouteUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTE_UPDATE}
            element={
              <RequireAuth>
                <RouteUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={ACTIONS_HISTORY}
            element={
              <RequireAuth>
                <ActionsHistory />
              </RequireAuth>
            }
          />
          <Route
            path={SESSIONS_HISTORY}
            element={
              <RequireAuth>
                <SessionsHistory />
              </RequireAuth>
            }
          />
          <Route
            path={USERS_MANAGEMENT}
            element={
              <RequireAuth>
                <UsersManagement />
              </RequireAuth>
            }
          />
          <Route
            path={USER_CREATE}
            element={
              <RequireAuth>
                <UserUpsert />
              </RequireAuth>
            }
          />
          <Route
            path={USER_ROUTES}
            element={
              <RequireAuth>
                <UserRoutes />
              </RequireAuth>
            }
          />
          <Route
            path={USER_UPDATE}
            element={
              <RequireAuth>
                <UserUpsert />
              </RequireAuth>
            }
          />
        </Route>

        {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
        <Route path='*' element={<NotFound />} />
        {/* </Route> */}
      </Routes>
      {/* </AuthProvider> */}
    </Router>
  );
};
