/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import { map } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SalesExportSalesHtmlResponseType } from '../../../../services/Sales/types';
import { salesListSlice } from '../../../../stores';
import { logoUrl } from '../../../../theme';

export const exportSalesToHTML = async (
  apiData: SalesExportSalesHtmlResponseType,
  users: {
    id: number;
    value: string;
  }[],
  areas: {
    id: number;
    value: string;
  }[],
  routes: {
    id: number;
    value: string;
    areaId: number;
  }[],
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  workerOne: (
    apiData: SalesExportSalesHtmlResponseType,
    users: {
      id: number;
      value: string;
    }[],
    areas: {
      id: number;
      value: string;
    }[],
    routes: {
      id: number;
      value: string;
      areaId: number;
    }[],
  ) => Promise<
    {
      areaId: number;
      registerDate: string;
      areaName: string;
      operational: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        users: {
          userId: number;
          userName: string;
          partialCredit: number;
          partialDebit: number;
          partialPix: number;
          partialCash: number;
          partialTotal: number;
          routes: {
            routeId: number;
            routeName: string;
            routeCredit: number;
            routeDebit: number;
            routePix: number;
            routeCash: number;
            routeTotal: number;
          }[];
        }[];
      };
      driver: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        routes: {
          routeId: number;
          routeName: string;
          valueCredit: number;
          valueDebit: number;
          valuePix: number;
          valueCash: number;
          valueTotal: number;
        }[];
      };
    }[]
  >,
  workerTwo: (
    handledData: {
      areaId: number;
      registerDate: string;
      areaName: string;
      operational: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        users: {
          userId: number;
          userName: string;
          partialCredit: number;
          partialDebit: number;
          partialPix: number;
          partialCash: number;
          partialTotal: number;
          routes: {
            routeId: number;
            routeName: string;
            routeCredit: number;
            routeDebit: number;
            routePix: number;
            routeCash: number;
            routeTotal: number;
          }[];
        }[];
      };
      driver: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        routes: {
          routeId: number;
          routeName: string;
          valueCredit: number;
          valueDebit: number;
          valuePix: number;
          valueCash: number;
          valueTotal: number;
        }[];
      };
    }[],
    apiData: SalesExportSalesHtmlResponseType,
    users: {
      id: number;
      value: string;
    }[],
    areas: {
      id: number;
      value: string;
    }[],
    routes: {
      id: number;
      value: string;
      areaId: number;
    }[],
  ) => Promise<
    {
      areaId: number;
      registerDate: string;
      areaName: string;
      operational: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        users: {
          userId: number;
          userName: string;
          partialCredit: number;
          partialDebit: number;
          partialPix: number;
          partialCash: number;
          partialTotal: number;
          routes: {
            routeId: number;
            routeName: string;
            routeCredit: number;
            routeDebit: number;
            routePix: number;
            routeCash: number;
            routeTotal: number;
          }[];
        }[];
      };
      driver: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        routes: {
          routeId: number;
          routeName: string;
          valueCredit: number;
          valueDebit: number;
          valuePix: number;
          valueCash: number;
          valueTotal: number;
        }[];
      };
    }[]
  >,
  workerThree: (
    handledData: {
      areaId: number;
      registerDate: string;
      areaName: string;
      operational: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        users: {
          userId: number;
          userName: string;
          partialCredit: number;
          partialDebit: number;
          partialPix: number;
          partialCash: number;
          partialTotal: number;
          routes: {
            routeId: number;
            routeName: string;
            routeCredit: number;
            routeDebit: number;
            routePix: number;
            routeCash: number;
            routeTotal: number;
          }[];
        }[];
      };
      driver: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        routes: {
          routeId: number;
          routeName: string;
          valueCredit: number;
          valueDebit: number;
          valuePix: number;
          valueCash: number;
          valueTotal: number;
        }[];
      };
    }[],
  ) => Promise<
    {
      areaId: number;
      registerDate: string;
      areaName: string;
      operational: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        users: {
          userId: number;
          userName: string;
          partialCredit: number;
          partialDebit: number;
          partialPix: number;
          partialCash: number;
          partialTotal: number;
          routes: {
            routeId: number;
            routeName: string;
            routeCredit: number;
            routeDebit: number;
            routePix: number;
            routeCash: number;
            routeTotal: number;
          }[];
        }[];
      };
      driver: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        routes: {
          routeId: number;
          routeName: string;
          valueCredit: number;
          valueDebit: number;
          valuePix: number;
          valueCash: number;
          valueTotal: number;
        }[];
      };
    }[]
  >,
  workerFour: (
    handledData: {
      areaId: number;
      registerDate: string;
      areaName: string;
      operational: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        users: {
          userId: number;
          userName: string;
          partialCredit: number;
          partialDebit: number;
          partialPix: number;
          partialCash: number;
          partialTotal: number;
          routes: {
            routeId: number;
            routeName: string;
            routeCredit: number;
            routeDebit: number;
            routePix: number;
            routeCash: number;
            routeTotal: number;
          }[];
        }[];
      };
      driver: {
        totalCredit: number;
        totalDebit: number;
        totalPix: number;
        totalCash: number;
        total: number;
        routes: {
          routeId: number;
          routeName: string;
          valueCredit: number;
          valueDebit: number;
          valuePix: number;
          valueCash: number;
          valueTotal: number;
        }[];
      };
    }[],
  ) => Promise<
    {
      title: string;
      body: string;
    }[]
  >,
): Promise<void> => {
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));

  let handledApiData: {
    title: string;
    body: string;
  }[] = [];
  if (apiData.salesUser && apiData.salesDriver) {
    handledApiData = await workerOne(apiData, users, areas, routes).then(
      async (workerOneReturn) => {
        dispatch(salesListSlice.actions.setSalesExportPercentage(40));
        return workerTwo(workerOneReturn, apiData, users, areas, routes).then(
          async (workerTwoReturn) => {
            dispatch(salesListSlice.actions.setSalesExportPercentage(60));
            return workerThree(workerTwoReturn).then(
              async (workerTwoReturn) => {
                dispatch(salesListSlice.actions.setSalesExportPercentage(70));
                return workerFour(workerTwoReturn);
              },
            );
          },
        );
      },
    );
  }

  dispatch(salesListSlice.actions.setSalesExportPercentage(90));

  const zip = new JSZip();

  map(handledApiData, function (item) {
    zip.file(
      `${item.title}.html`,
      `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Relatório financeiro resumido</title>
        <style>

          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: white;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 12px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }

          h3 {
            font-size: 17px;
            font-weight: 600;
          }

          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }

          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */

          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 28px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 16px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  ${item.body}
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `,
    );
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `relatorio-vendas-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const handleApiDataSalesToHTMLPartOne = (
  apiData: SalesExportSalesHtmlResponseType,
  users: {
    id: number;
    value: string;
  }[],
  areas: {
    id: number;
    value: string;
  }[],
  routes: {
    id: number;
    value: string;
    areaId: number;
  }[],
) => {
  // Populating handledData from salesUser (operational/ first part of html)
  const handledData: {
    areaId: number;
    registerDate: string;
    areaName: string;
    operational: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      users: {
        userId: number;
        userName: string;
        partialCredit: number;
        partialDebit: number;
        partialPix: number;
        partialCash: number;
        partialTotal: number;
        routes: {
          routeId: number;
          routeName: string;
          routeCredit: number;
          routeDebit: number;
          routePix: number;
          routeCash: number;
          routeTotal: number;
        }[];
      }[];
    };
    driver: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      routes: {
        routeId: number;
        routeName: string;
        valueCredit: number;
        valueDebit: number;
        valuePix: number;
        valueCash: number;
        valueTotal: number;
      }[];
    };
  }[] = [];

  apiData.salesUser.map((item) => {
    // Finds user object first
    const userObj = users.find((auxItem) => {
      return auxItem.id === item.userId;
    });
    // Finding route object from filters
    const routeObj = routes.find((auxItem) => {
      return auxItem.id === item.routeId;
    });
    if (userObj && routeObj) {
      // Finding area object from filters
      const areaObj = areas.find((auxItem) => {
        return auxItem.id === routeObj.areaId;
      });
      if (areaObj) {
        // First: check if entry already exists on handledUsersData for that area && date
        const dataIdx = handledData.findIndex((auxItem) => {
          return (
            auxItem.registerDate === item.registerDate &&
            auxItem.areaId === areaObj.id
          );
        });
        if (dataIdx !== -1) {
          // If exists, check if user entry exists in it
          const userIdx = handledData[dataIdx].operational.users.findIndex(
            (auxItem) => {
              return auxItem.userId === userObj.id;
            },
          );
          if (userIdx !== -1) {
            // If exists, check if route entry exists in it
            const routeIdx = handledData[dataIdx].operational.users[
              userIdx
            ].routes.findIndex((auxItem) => {
              auxItem.routeId === item.routeId;
            });
            if (routeIdx !== -1) {
              // If exists, adds new paymentMethod and value to it and updates total
              switch (item.paymentMethod) {
                case 'CREDITO':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routeCredit = item.sumPrice;
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialCredit += item.sumPrice;
                  handledData[dataIdx].operational.totalCredit += item.sumPrice;
                  break;
                case 'DEBITO':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routeDebit = item.sumPrice;
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialDebit += item.sumPrice;
                  handledData[dataIdx].operational.totalDebit += item.sumPrice;
                  break;
                case 'PIX':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routePix = item.sumPrice;
                  handledData[dataIdx].operational.users[userIdx].partialPix +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalPix += item.sumPrice;
                  break;
                case 'DINHEIRO':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routeCash = item.sumPrice;
                  handledData[dataIdx].operational.users[userIdx].partialCash +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalCash += item.sumPrice;
                  break;
              }
              handledData[dataIdx].operational.users[userIdx].routes[
                routeIdx
              ].routeTotal += item.sumPrice;
              handledData[dataIdx].operational.users[userIdx].partialTotal +=
                item.sumPrice;
              handledData[dataIdx].operational.total += item.sumPrice;
            } else {
              // If not, adds route entry with paymentMethod and value to it
              handledData[dataIdx].operational.users[userIdx].routes.push({
                routeId: routeObj.id,
                routeName: routeObj.value,
                routeCredit:
                  item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                routeDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                routePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                routeCash:
                  item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                routeTotal: item.sumPrice,
              });
              switch (item.paymentMethod) {
                case 'CREDITO':
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialCredit += item.sumPrice;
                  handledData[dataIdx].operational.totalCredit += item.sumPrice;
                  break;
                case 'DEBITO':
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialDebit += item.sumPrice;
                  handledData[dataIdx].operational.totalDebit += item.sumPrice;
                  break;
                case 'PIX':
                  handledData[dataIdx].operational.users[userIdx].partialPix +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalPix += item.sumPrice;
                  break;
                case 'DINHEIRO':
                  handledData[dataIdx].operational.users[userIdx].partialCash +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalCash += item.sumPrice;
                  break;
              }
              handledData[dataIdx].operational.users[userIdx].partialTotal +=
                item.sumPrice;
              handledData[dataIdx].operational.total += item.sumPrice;
            }
          } else {
            // If not, adds user entry
            handledData[dataIdx].operational.users.push({
              userId: userObj.id,
              userName: userObj.value,
              partialCredit:
                item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              partialDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              partialPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              partialCash:
                item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              partialTotal: item.sumPrice,
              routes: [
                {
                  routeId: routeObj.id,
                  routeName: routeObj.value,
                  routeCredit:
                    item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                  routeDebit:
                    item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                  routePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                  routeCash:
                    item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                  routeTotal: item.sumPrice,
                },
              ],
            });
            handledData[dataIdx].operational.total += item.sumPrice;
          }
        } else {
          // If not, adds area entry
          handledData.push({
            registerDate: item.registerDate,
            areaId: areaObj.id,
            areaName: areaObj.value,
            operational: {
              totalCredit: item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              totalDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              totalPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              totalCash: item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              total: item.sumPrice,
              users: [
                {
                  userId: userObj.id,
                  userName: userObj.value,
                  partialCredit:
                    item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                  partialDebit:
                    item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                  partialPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                  partialCash:
                    item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                  partialTotal: item.sumPrice,
                  routes: [
                    {
                      routeId: routeObj.id,
                      routeName: routeObj.value,
                      routeCredit:
                        item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                      routeDebit:
                        item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                      routePix:
                        item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                      routeCash:
                        item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                      routeTotal: item.sumPrice,
                    },
                  ],
                },
              ],
            },
            driver: {
              totalCredit: 0,
              totalDebit: 0,
              totalPix: 0,
              totalCash: 0,
              total: 0,
              routes: [],
            },
          });
        }
      }
    }
  });

  return handledData;
};

export const handleApiDataSalesToHTMLPartTwo = (
  handledData: {
    areaId: number;
    registerDate: string;
    areaName: string;
    operational: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      users: {
        userId: number;
        userName: string;
        partialCredit: number;
        partialDebit: number;
        partialPix: number;
        partialCash: number;
        partialTotal: number;
        routes: {
          routeId: number;
          routeName: string;
          routeCredit: number;
          routeDebit: number;
          routePix: number;
          routeCash: number;
          routeTotal: number;
        }[];
      }[];
    };
    driver: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      routes: {
        routeId: number;
        routeName: string;
        valueCredit: number;
        valueDebit: number;
        valuePix: number;
        valueCash: number;
        valueTotal: number;
      }[];
    };
  }[],
  apiData: SalesExportSalesHtmlResponseType,
  users: {
    id: number;
    value: string;
  }[],
  areas: {
    id: number;
    value: string;
  }[],
  routes: {
    id: number;
    value: string;
    areaId: number;
  }[],
) => {
  // Adding salesDrivers to handledData (driver/ second part of html)
  apiData.salesDriver.map((item) => {
    // Finding route object from filters
    const routeObj = routes.find((auxItem) => {
      return auxItem.id === item.routeId;
    });
    if (routeObj) {
      const areaObj = areas.find((auxItem) => {
        return auxItem.id === routeObj.areaId;
      });
      if (areaObj) {
        // Check if already exists drivers entry
        const dataIdx = handledData.findIndex((auxItem) => {
          return (
            auxItem.areaId === routeObj.areaId &&
            auxItem.registerDate === item.registerDate
          );
        });
        if (dataIdx !== -1) {
          // If exists, check if route entry exists
          const routeIdx = handledData[dataIdx].driver.routes.findIndex(
            (auxItem) => {
              return auxItem.routeId === item.routeId;
            },
          );
          if (routeIdx !== -1) {
            // If exists, updates with value and adds to total
            switch (item.paymentMethod) {
              case 'CREDITO':
                handledData[dataIdx].driver.routes[routeIdx].valueCredit =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalCredit += item.sumPrice;
                break;
              case 'DEBITO':
                handledData[dataIdx].driver.routes[routeIdx].valueDebit =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalDebit += item.sumPrice;
                break;
              case 'PIX':
                handledData[dataIdx].driver.routes[routeIdx].valuePix =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalPix += item.sumPrice;
                break;
              case 'DINHEIRO':
                handledData[dataIdx].driver.routes[routeIdx].valueCash =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalCash += item.sumPrice;
                break;
            }
            handledData[dataIdx].driver.total += item.sumPrice;
          } else {
            // Creates route entry
            handledData[dataIdx].driver.routes.push({
              routeId: item.routeId,
              routeName: routeObj.value,
              valueCredit: item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              valueDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              valuePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              valueCash: item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              valueTotal: item.sumPrice,
            });
            switch (item.paymentMethod) {
              case 'CREDITO':
                handledData[dataIdx].driver.totalCredit += item.sumPrice;
                break;
              case 'DEBITO':
                handledData[dataIdx].driver.totalDebit += item.sumPrice;
                break;
              case 'PIX':
                handledData[dataIdx].driver.totalPix += item.sumPrice;
                break;
              case 'DINHEIRO':
                handledData[dataIdx].driver.totalCash += item.sumPrice;
                break;
            }
            handledData[dataIdx].driver.total += item.sumPrice;
          }
        } else {
          // Creates drivers entry
          handledData.push({
            areaId: areaObj.id,
            registerDate: item.registerDate,
            areaName: areaObj?.value,
            operational: {
              totalCredit: 0,
              totalDebit: 0,
              totalPix: 0,
              totalCash: 0,
              total: 0,
              users: [],
            },
            driver: {
              totalCredit: item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              totalDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              totalPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              totalCash: item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              total: item.sumPrice,
              routes: [
                {
                  routeId: item.routeId,
                  routeName: routeObj.value,
                  valueCredit:
                    item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                  valueDebit:
                    item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                  valuePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                  valueCash:
                    item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                  valueTotal: item.sumPrice,
                },
              ],
            },
          });
        }
      }
    }
  });

  return handledData;
};

export const handleApiDataSalesToHTMLPartThree = (
  handledData: {
    areaId: number;
    registerDate: string;
    areaName: string;
    operational: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      users: {
        userId: number;
        userName: string;
        partialCredit: number;
        partialDebit: number;
        partialPix: number;
        partialCash: number;
        partialTotal: number;
        routes: {
          routeId: number;
          routeName: string;
          routeCredit: number;
          routeDebit: number;
          routePix: number;
          routeCash: number;
          routeTotal: number;
        }[];
      }[];
    };
    driver: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      routes: {
        routeId: number;
        routeName: string;
        valueCredit: number;
        valueDebit: number;
        valuePix: number;
        valueCash: number;
        valueTotal: number;
      }[];
    };
  }[],
) => {
  // Ordering alphabetically each array
  handledData.map((data) => {
    data.operational.users.map((user) => {
      const orderedUserRoutes = user.routes.sort((a, b) => {
        if (a.routeName.toLowerCase() < b.routeName.toLowerCase()) return -1;
        if (a.routeName.toLowerCase() > b.routeName.toLowerCase()) return 1;
        return 0;
      });
      user.routes = orderedUserRoutes;
    });

    const orderedUsers = data.operational.users.sort((a, b) => {
      if (a.userName.toLowerCase() < b.userName.toLowerCase()) return -1;
      if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
      return 0;
    });
    data.operational.users = orderedUsers;

    const orderedDriverRoutes = data.driver.routes.sort((a, b) => {
      if (a.routeName.toLowerCase() < b.routeName.toLowerCase()) return -1;
      if (a.routeName.toLowerCase() > b.routeName.toLowerCase()) return 1;
      return 0;
    });
    data.driver.routes = orderedDriverRoutes;
  });

  return handledData;
};

export const handleApiDataSalesToHTMLPartFour = (
  handledData: {
    areaId: number;
    registerDate: string;
    areaName: string;
    operational: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      users: {
        userId: number;
        userName: string;
        partialCredit: number;
        partialDebit: number;
        partialPix: number;
        partialCash: number;
        partialTotal: number;
        routes: {
          routeId: number;
          routeName: string;
          routeCredit: number;
          routeDebit: number;
          routePix: number;
          routeCash: number;
          routeTotal: number;
        }[];
      }[];
    };
    driver: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      routes: {
        routeId: number;
        routeName: string;
        valueCredit: number;
        valueDebit: number;
        valuePix: number;
        valueCash: number;
        valueTotal: number;
      }[];
    };
  }[],
) => {
  // Initializing stuff
  const htmlStuff: {
    title: string;
    body: string;
  }[] = [];

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  // Creating <tr>s
  handledData.forEach((data, index) => {
    const title = `${data.areaName} ${data.registerDate}`;
    let operationalTr = '';
    let driverTr = '';

    // Creating operational <tr>s
    data.operational.users.map((item) => {
      const currUserRows: string[] = ['', '', '', '', '', ''];
      item.routes.map((route) => {
        currUserRows[0] += '<br />' + route.routeName;
        currUserRows[1] +=
          '<br />' + route.routeCredit.toFixed(2).toString().replace('.', ',');
        currUserRows[2] +=
          '<br />' + route.routeDebit.toFixed(2).toString().replace('.', ',');
        currUserRows[3] +=
          '<br />' + route.routePix.toFixed(2).toString().replace('.', ',');
        currUserRows[4] +=
          '<br />' + route.routeCash.toFixed(2).toString().replace('.', ',');
        currUserRows[5] +=
          '<br />' + route.routeTotal.toFixed(2).toString().replace('.', ',');
      });

      operationalTr += `
        <tr>
          <td>
            <strong> ${item.userId}</strong>
            ${currUserRows[0]}
          </td>
          <td>
            <strong> ${item.partialCredit
              .toFixed(2)
              .toString()
              .replace('.', ',')}</strong>
            ${currUserRows[1]}
          </td>
          <td>
            <strong> ${item.partialDebit
              .toFixed(2)
              .toString()
              .replace('.', ',')}</strong>
            ${currUserRows[2]}
          </td>
          <td>
            <strong> ${item.partialPix
              .toFixed(2)
              .toString()
              .replace('.', ',')}</strong>
            ${currUserRows[3]}
          </td>
          <td>
            <strong> ${item.partialCash
              .toFixed(2)
              .toString()
              .replace('.', ',')}</strong>
            ${currUserRows[4]}
          </td>
          <td>
            <strong> ${item.partialTotal
              .toFixed(2)
              .toString()
              .replace('.', ',')}</strong>
            ${currUserRows[5]}
          </td>
        </tr>
      `;
    });

    // Creating driver <tr>s
    data.driver.routes.map((item) => {
      if (item) {
        driverTr += `
        <tr>
          <td><strong>${item.routeName}</strong></td>
          <td><strong> ${item.valueCredit
            .toFixed(2)
            .toString()
            .replace('.', ',')}</strong></td>
          <td><strong> ${item.valueDebit
            .toFixed(2)
            .toString()
            .replace('.', ',')}</strong></td>
          <td><strong> ${item.valuePix
            .toFixed(2)
            .toString()
            .replace('.', ',')}</strong></td>
          <td><strong> ${item.valueCash
            .toFixed(2)
            .toString()
            .replace('.', ',')}</strong></td>
          <td><strong> ${item.valueTotal
            .toFixed(2)
            .toString()
            .replace('.', ',')}</strong></td>
        </tr>`;
      }
    });

    // Mounting html for this area/ date
    const fullBody =
      operationalTr.length === 0
        ? `
          <tr>
            <td class="wrapper">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    <h1>Relatório financeiro resumido</h1>
                    <h3>
                      ${handledData[index].areaName} (${formatDateTime(
            handledData[index].registerDate,
          )})
                    </h3>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td align="left">
                            <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                              <thead>
                                <tr>
                                  <th align="left">Motorista</th>
                                  <th align="left">Crédito</th>
                                  <th align="left">Débito</th>
                                  <th align="left">Pix</th>
                                  <th align="left">Dinheiro</th>
                                  <th align="left">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                ${driverTr}
                                <tr>
                                  <td><strong>Total motorista:</strong></td>
                                  <td><strong> ${data.driver.totalCredit
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.driver.totalDebit
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.driver.totalPix
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.driver.totalCash
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.driver.total
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                </tr>
                                <tr>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                </tr>
                                <tr>
                                  <td><strong>Total geral:</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalCredit +
                                    data.driver.totalCredit
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalDebit +
                                    data.driver.totalDebit
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalPix +
                                    data.driver.totalPix
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalCash +
                                    data.driver.totalCash
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.total + data.driver.total
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        `
        : driverTr.length === 0
        ? `
          <tr>
            <td class="wrapper">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    <h1>Relatório financeiro resumido</h1>
                    <h3>
                      ${handledData[index].areaName} (${formatDateTime(
            handledData[index].registerDate,
          )})
                    </h3>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td align="left">
                            <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                              <thead>
                                <tr>
                                  <th align="left">Operacional</th>
                                  <th align="left">Crédito</th>
                                  <th align="left">Débito</th>
                                  <th align="left">Pix</th>
                                  <th align="left">Dinheiro</th>
                                  <th align="left">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                ${operationalTr}
                                <tr>
                                  <td><strong>Total operacional:</strong></td>
                                  <td><strong> ${data.operational.totalCredit
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.operational.totalDebit
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.operational.totalPix
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.operational.totalCash
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${data.operational.total
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                </tr>
                                <tr>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                </tr>
                                <tr>
                                  <td><strong>Total geral:</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalCredit +
                                    data.driver.totalCredit
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalDebit +
                                    data.driver.totalDebit
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalPix +
                                    data.driver.totalPix
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.totalCash +
                                    data.driver.totalCash
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                  <td><strong> ${(
                                    data.operational.total + data.driver.total
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}</strong></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        `
        : `
        <tr>
          <td class="wrapper">
            <table border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td>
                  <h1>Relatório financeiro resumido</h1>
                  <h3>
                    ${handledData[index].areaName} (${formatDateTime(
            handledData[index].registerDate,
          )})
                  </h3>
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td align="left">
                          <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                            <thead>
                              <tr>
                                <th align="left">Operacional</th>
                                <th align="left">Crédito</th>
                                <th align="left">Débito</th>
                                <th align="left">Pix</th>
                                <th align="left">Dinheiro</th>
                                <th align="left">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              ${operationalTr}
                              <tr>
                                <td><strong>Total operacional:</strong></td>
                                <td><strong> ${data.operational.totalCredit
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.operational.totalDebit
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.operational.totalPix
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.operational.totalCash
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.operational.total
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                              </tr>
                              <tr>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                              </tr>
                              <tr>
                                <td><strong>Motorista</strong></td>
                                <td><strong>Crédito</strong></td>
                                <td><strong>Débito</strong></td>
                                <td><strong>Pix</strong></td>
                                <td><strong>Dinheiro</strong></td>
                                <td><strong>Total</strong></td>
                              </tr>
                              ${driverTr}
                              <tr>
                                <td><strong>Total motorista:</strong></td>
                                <td><strong> ${data.driver.totalCredit
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.driver.totalDebit
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.driver.totalPix
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.driver.totalCash
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${data.driver.total
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                              </tr>
                              <tr>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                              </tr>
                              <tr>
                                <td><strong>Total geral:</strong></td>
                                <td><strong> ${(
                                  data.operational.totalCredit +
                                  data.driver.totalCredit
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${(
                                  data.operational.totalDebit +
                                  data.driver.totalDebit
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${(
                                  data.operational.totalPix +
                                  data.driver.totalPix
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${(
                                  data.operational.totalCash +
                                  data.driver.totalCash
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                                <td><strong> ${(
                                  data.operational.total + data.driver.total
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      `;

    htmlStuff.push({
      title: title,
      body: fullBody,
    });
  });

  return htmlStuff;
};
