import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from '../../stores';

interface ProtectedRouteProps {
  allowedPage: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedPage }) => {
  const { profileId } = useSelector((state) => state.authReducer);

  if (profileId === 9) {
    return <Navigate to={allowedPage} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
