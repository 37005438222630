import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { Typography, Box, Button as MuiButton, Skeleton } from '@mui/material';
import { includes, times } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../../components';
import { NOTIFICATIONS_MANAGEMENT } from '../../../routes';
import {
  useDispatch,
  useSelector,
  saleSlice,
  notificationsListSlice,
} from '../../../stores';
import { handleDateTime, handleMoney } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const SaleInfoModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saleInfo, saleInfoOrigin, saleInfoLoading, saleInfoIsOpen } =
    useSelector((state) => state.saleReducer);
  const { profileId } = useSelector((state) => state.authReducer);

  const handleSendEmail = () => {
    if (saleInfo) {
      dispatch(
        saleSlice.actions.setSaleSendEmailId({
          saleId: saleInfo.id,
        }),
      );
      dispatch(saleSlice.actions.toggleSaleInfoIsOpen());
      dispatch(saleSlice.actions.toggleSaleSendEmailIsOpen());
    }
  };

  const handleEditPayment = () => {
    if (saleInfo) {
      dispatch(
        saleSlice.actions.setSaleEditPaymentId({
          saleId: saleInfo.id,
        }),
      );
      dispatch(saleSlice.actions.toggleSaleInfoIsOpen());
      dispatch(saleSlice.actions.toggleSaleEditPaymentIsOpen());
    }
  };

  const handleIrregularityClick = (irregularities: string | undefined) => {
    if (irregularities !== undefined) {
      dispatch(
        notificationsListSlice.actions.setIrregularityId(irregularities),
      );
      dispatch(saleSlice.actions.toggleSaleInfoIsOpen());
      navigate(NOTIFICATIONS_MANAGEMENT);
    }
  };

  return (
    <Modal
      open={saleInfoIsOpen}
      onClose={() => dispatch(saleSlice.actions.toggleSaleInfoIsOpen())}
      title='Detalhamento'
    >
      <>
        {saleInfoLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(14, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Veículo:
                </Typography>
                <Typography>{saleInfo?.vehicle}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Data de Registro:
                </Typography>
                <Typography>
                  {handleDateTime(saleInfo?.registerDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Data de Entrada:
                </Typography>
                <Typography>
                  {handleDateTime(saleInfo?.entryDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Data de Saída:
                </Typography>
                <Typography>
                  {handleDateTime(saleInfo?.exitDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Situação:
                </Typography>
                <Typography>{saleInfo?.situation}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Método de Pagamento:
                </Typography>
                <Typography>{saleInfo?.paymentMethod}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Valor:
                </Typography>
                <Typography>{handleMoney(saleInfo?.price)}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Valor Pago:
                </Typography>
                <Typography>{handleMoney(saleInfo?.paidPrice)}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Trecho:
                </Typography>
                <Typography>{saleInfo?.routeName}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Origem:
                </Typography>
                <Typography>
                  {saleInfo?.userLogin || `Indisponível`} ({saleInfo?.origin})
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Irregularidade:
                </Typography>
                <Typography>
                  {saleInfo?.irregularities &&
                  saleInfo?.irregularities !== '--' &&
                  profileId !== 9 ? (
                    <MuiButton
                      variant='text'
                      size='small'
                      onClick={() =>
                        handleIrregularityClick(
                          saleInfo.irregularities
                            ? saleInfo.irregularities
                            : undefined,
                        )
                      }
                      sx={{
                        color: 'blue',
                        '&:hover': {
                          textDecoration: 'underline blue',
                        },
                        minWidth: 0,
                      }}
                    >
                      <Typography id='modal-modal-description'>
                        {saleInfo.irregularities}
                      </Typography>
                    </MuiButton>
                  ) : saleInfo?.irregularities &&
                    saleInfo?.irregularities !== '--' &&
                    profileId === 9 ? (
                    <Typography>{saleInfo.irregularities}</Typography>
                  ) : (
                    '--'
                  )}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Reimpressão:
                </Typography>
                <Typography>
                  {saleInfo?.reprint === 0 ? 'Não' : 'Sim'}
                  {saleInfo?.reprintObservation &&
                    '\n' + saleInfo.reprintObservation}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Sem Placa:
                </Typography>
                <Typography>
                  {saleInfo?.hasNoPlate === 0 ? 'Não' : 'Sim'}
                  {saleInfo?.hasNoPlateObservation &&
                    '\n' + saleInfo.hasNoPlateObservation}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Máquina POS:
                </Typography>
                <Typography>
                  {saleInfo?.posMachine === 1
                    ? 'Lio'
                    : saleInfo?.posMachine === 2
                    ? 'Getnet'
                    : 'Não'}
                </Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        {profileId !== 9 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <MuiButton
              variant='contained'
              startIcon={<EmailIcon />}
              color='success'
              onClick={() => handleSendEmail()}
              sx={{ width: '100%', margin: 0.5 }}
            >
              Comprovante por email
            </MuiButton>
            {includes(saleInfoOrigin, 'Operacional') &&
              saleInfo?.paymentMethod === 'DINHEIRO' && (
                <MuiButton
                  variant='contained'
                  color='secondary'
                  startIcon={<EditIcon />}
                  onClick={() => handleEditPayment()}
                  sx={{ width: '100%', margin: 0.5 }}
                >
                  Editar pagamento
                </MuiButton>
              )}
          </Box>
        )}
      </>
    </Modal>
  );
};
