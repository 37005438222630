import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { replace } from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchSalesInfoService,
  fetchSalesEditPaymentService,
  fetchSalesSendEmailService,
} from '../../services';
import {
  SaleInfoOriginType,
  SaleIdToEditPaymentType,
  SaleIdToSendEmailType,
  SaleStateType,
} from './types';

const initialState: SaleStateType = {
  saleInfo: null,
  saleInfoOrigin: '',
  saleInfoLoading: false,
  saleEditPaymentLoading: false,
  saleInfoIsOpen: false,
  saleSendEmailIsOpen: false,
  saleIdToSendEmail: '',
  saleSendEmailLoading: false,
  saleEditPaymentIsOpen: false,
  saleIdToEditPayment: '',
};

export const fetchSaleInfo = createAsyncThunk(
  'salesInfo',
  async (params: { saleId: string }) => {
    return await fetchSalesInfoService(params);
  },
);

export const fetchSaleSendEmail = createAsyncThunk(
  'salesSendEmail',
  async (params: { saleId: string; email: string; name: string }) => {
    return await fetchSalesSendEmailService(params);
  },
);

export const fetchSaleEditPayment = createAsyncThunk(
  'salesEditPayment',
  async (params: {
    saleId: string;
    paymentMethod: string;
    login: string;
    password: string;
  }) => {
    return await fetchSalesEditPaymentService(params);
  },
);

export const saleSlice = createSlice({
  name: 'salesInfo',
  initialState,
  reducers: {
    setSaleInfoOrigin(state, action: PayloadAction<SaleInfoOriginType>) {
      state.saleInfoOrigin = action.payload.origin;
    },
    toggleSaleInfoIsOpen(state) {
      state.saleInfoIsOpen
        ? (state.saleInfoIsOpen = false)
        : (state.saleInfoIsOpen = true);
    },
    toggleSaleEditPaymentIsOpen(state) {
      state.saleEditPaymentIsOpen
        ? (state.saleEditPaymentIsOpen = false)
        : (state.saleEditPaymentIsOpen = true);
    },
    setSaleEditPaymentId(
      state,
      action: PayloadAction<SaleIdToEditPaymentType>,
    ) {
      state.saleIdToEditPayment = action.payload.saleId;
    },
    toggleSaleSendEmailIsOpen(state) {
      state.saleSendEmailIsOpen
        ? (state.saleSendEmailIsOpen = false)
        : (state.saleSendEmailIsOpen = true);
    },
    setSaleSendEmailId(state, action: PayloadAction<SaleIdToSendEmailType>) {
      state.saleIdToSendEmail = action.payload.saleId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSaleInfo.pending, (state) => {
      return {
        ...state,
        saleInfoLoading: true,
      };
    }),
      builder.addCase(fetchSaleInfo.fulfilled, (state, res) => {
        return {
          ...state,
          saleInfo: {
            id: res.payload.data.id,
            plate: res.payload.data.plate,
            type: res.payload.data.type,
            vehicle: res.payload.data.type
              ? `${res.payload.data.plate} (${res.payload.data.type})`
              : `${res.payload.data.plate}`,
            registerDateTime: res.payload.data.registerDateTime,
            entryDateTime: res.payload.data.entryDateTime,
            exitDateTime: res.payload.data.exitDateTime,
            situation: res.payload.data.situation,
            paymentMethod: res.payload.data.paymentMethod,
            price: res.payload.data.price,
            paidPrice: res.payload.data.paidPrice,
            routeName: res.payload.data.routeName,
            userName: res.payload.data.userName,
            userLogin: res.payload.data.userLogin,
            driverName: res.payload.data.driverName,
            origin: res.payload.data.userLogin ? `Operacional` : `Motorista`,
            irregularities: res.payload.data.irregularities
              ? replace(
                  replace(res.payload.data.irregularities, '[', ''),
                  ']',
                  '',
                )
              : '--',
            reprint: res.payload.data.reprint,
            reprintObservation: res.payload.data.reprintObservation,
            hasNoPlate: res.payload.data.hasNoPlate,
            hasNoPlateObservation: res.payload.data.hasNoPlateObservation,
            posMachine: res.payload.data.posMachine,
          },
          saleInfoLoading: false,
        };
      }),
      builder.addCase(fetchSaleInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          saleInfoLoading: false,
        };
      });

    builder.addCase(fetchSaleSendEmail.pending, (state) => {
      return {
        ...state,
        saleSendEmailLoading: true,
      };
    }),
      builder.addCase(fetchSaleSendEmail.fulfilled, (state, res) => {
        if (res.payload) {
          toast.success(res.payload.data.msg);
          return {
            ...state,
            saleSendEmailLoading: false,
            saleSendEmailIsOpen: false,
          };
        }
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          saleSendEmailLoading: false,
          saleSendEmailIsOpen: false,
        };
      }),
      builder.addCase(fetchSaleSendEmail.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          saleSendEmailLoading: false,
          saleSendEmailIsOpen: false,
        };
      });

    builder.addCase(fetchSaleEditPayment.pending, (state) => {
      return {
        ...state,
        saleEditPaymentLoading: true,
      };
    }),
      builder.addCase(fetchSaleEditPayment.fulfilled, (state, res) => {
        toast.success('Método de pagamento editado com sucesso.');
        return {
          ...state,
          saleEditPaymentLoading: false,
        };
      }),
      builder.addCase(fetchSaleEditPayment.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          saleEditPaymentLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleSaleInfoIsOpen,
  toggleSaleEditPaymentIsOpen,
  setSaleEditPaymentId,
} = saleSlice.actions;
