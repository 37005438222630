import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Typography,
  Box,
  Button as MuiButton,
  Skeleton,
  ImageList,
  ImageListItem,
} from '@mui/material';
import { times } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, SplitButton } from '../../../components';
import { NOTIFICATIONS_EDIT_RECORDS } from '../../../routes';
import {
  useDispatch,
  useSelector,
  notificationSlice,
  fetchNotificationDetailsExportHTML,
  fetchNotificationInfoForEditRecords,
} from '../../../stores';
import {
  handleDateTime,
  handleMapLink,
  handleMoney,
} from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const NotificationInfoModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    notificationInfo,
    notificationInfoSituation,
    notificationInfoLoading,
    notificationInfoIsOpen,
    notificationDetailsExportHTMLLoading,
    notificationInfoForEditRecordsLoading,
  } = useSelector((state) => state.notificationReducer);

  const handleExportHTML = () => {
    notificationInfo &&
      dispatch(
        fetchNotificationDetailsExportHTML({
          notificationId: notificationInfo.id,
        }),
      );
  };

  const handleCancelNotification = () => {
    if (notificationInfo) {
      dispatch(
        notificationSlice.actions.setNotificationCancelId({
          notificationId: notificationInfo.id,
        }),
      );
      dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
      dispatch(notificationSlice.actions.toggleNotificationCancelIsOpen());
    }
  };

  const getNotificationModalBody = () => {
    switch (notificationInfoSituation) {
      case 'Irregular':
        return (
          <>
            {notificationInfoLoading ? (
              <StyledModalWrapper>
                <StyledModalInnerWrapper>
                  {times(6, () => (
                    <Skeleton animation='wave' height={29} width='100%' />
                  ))}
                  <Skeleton animation='wave' height={100} width='100%' />
                  <Skeleton animation='wave' height={39} width='100%' />
                  <Skeleton animation='wave' height={200} width='100%' />
                </StyledModalInnerWrapper>
              </StyledModalWrapper>
            ) : (
              <>
                <StyledModalWrapper>
                  <StyledModalInnerWrapper>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Preço:
                      </Typography>
                      <Typography>
                        {notificationInfo?.price
                          ? handleMoney(notificationInfo?.price)
                          : '-'}
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Usuário:
                      </Typography>
                      <Typography>{notificationInfo?.userLogin}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Veículo:
                      </Typography>
                      <Typography>{notificationInfo?.plate}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Data de Criação:
                      </Typography>
                      <Typography>
                        {handleDateTime(notificationInfo?.registerDateTime)}
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Situação:
                      </Typography>
                      <Typography>Irregular</Typography>
                    </StyledModalTextRow>
                    {notificationInfo?.observation && (
                      <>
                        <StyledModalTextRow>
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Observação:
                          </Typography>
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ textAlign: 'right' }}>
                            {notificationInfo?.observation}
                          </Typography>
                        </StyledModalTextRow>
                      </>
                    )}
                    <StyledModalTextRow>
                      <Typography
                        color='primary'
                        sx={{ fontWeight: 'bold', width: '100%' }}
                      >
                        Trecho:
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography sx={{ textAlign: 'right' }}>
                        {notificationInfo?.routeName}
                      </Typography>
                    </StyledModalTextRow>
                    <Box
                      sx={{
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <StyledModalTextRow>
                        <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                          Localização:
                        </Typography>
                        <a
                          href={handleMapLink(
                            notificationInfo?.latitude.toString(),
                            notificationInfo?.longitude.toString(),
                          )}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Typography>Ver no mapa</Typography>
                        </a>
                      </StyledModalTextRow>
                    </Box>
                    {notificationInfo &&
                      (notificationInfo.plateImg ||
                        notificationInfo.locationImg) && (
                        <Box
                          sx={{
                            width: '100%',
                            height: 450,
                            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                            marginTop: '10px',
                            paddingTop: '10px',
                          }}
                        >
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Imagens
                          </Typography>
                          <ImageList
                            color='primary'
                            sx={{
                              fontWeight: 'bold',
                              marginTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            {notificationInfo.plateImg && (
                              <ImageListItem>
                                <img
                                  src={notificationInfo.plateImg}
                                  srcSet={notificationInfo.plateImg}
                                  alt={'Imagem da Placa'}
                                  loading='lazy'
                                />
                              </ImageListItem>
                            )}
                            {notificationInfo.locationImg && (
                              <ImageListItem>
                                <img
                                  src={notificationInfo.locationImg}
                                  srcSet={notificationInfo.locationImg}
                                  alt={'Imagem do Local'}
                                  loading='lazy'
                                />
                              </ImageListItem>
                            )}
                          </ImageList>
                        </Box>
                      )}
                  </StyledModalInnerWrapper>
                </StyledModalWrapper>
              </>
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '20px',
              }}
            >
              <LoadingButton
                variant='contained'
                startIcon={<DownloadIcon />}
                color='primary'
                onClick={() => handleExportHTML()}
                loading={notificationDetailsExportHTMLLoading}
                sx={{ width: '100%', margin: 0.5 }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                  }}
                  noWrap
                >
                  Baixar detalhamento
                </Typography>
              </LoadingButton>
              <SplitButton
                label={notificationInfoForEditRecordsLoading ? '' : 'Editar'}
                startIcon={
                  !notificationInfoForEditRecordsLoading && <EditIcon />
                }
                loading={notificationInfoForEditRecordsLoading}
                sx={{ width: '100%', margin: 0.5 }}
                options={
                  moment(notificationInfo?.registerDateTime).isSame(
                    new Date(),
                    'day',
                  )
                    ? [
                        {
                          label: 'Editar tipo de veículo',
                          onClick: handleEditNotification,
                        },
                        {
                          label: 'Editar registros',
                          onClick: handleEditRegisters,
                        },
                      ]
                    : [
                        {
                          label: 'Editar tipo de veículo',
                          onClick: handleEditNotification,
                        },
                        {
                          label: 'Editar registros',
                          onClick: handleEditRegisters,
                        },
                        {
                          label: 'Editar preço',
                          onClick: handleEditPrice,
                        },
                      ]
                }
              />
              <MuiButton
                variant='contained'
                startIcon={<DeleteIcon />}
                color='secondary'
                onClick={() => handleCancelNotification()}
                sx={{ width: '100%', margin: 0.5 }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                  }}
                  noWrap
                >
                  Cancelar
                </Typography>
              </MuiButton>
            </Box>
          </>
        );
      case 'Paga':
        return (
          <>
            {notificationInfoLoading ? (
              <StyledModalWrapper>
                <StyledModalInnerWrapper>
                  {times(6, () => (
                    <Skeleton animation='wave' height={29} width='100%' />
                  ))}
                  <Skeleton animation='wave' height={100} width='100%' />
                  <Skeleton animation='wave' height={39} width='100%' />
                  <Skeleton animation='wave' height={200} width='100%' />
                </StyledModalInnerWrapper>
              </StyledModalWrapper>
            ) : (
              <>
                <StyledModalWrapper>
                  <StyledModalInnerWrapper>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Usuário:
                      </Typography>
                      <Typography>{notificationInfo?.userName}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Veículo:
                      </Typography>
                      <Typography>{notificationInfo?.plate}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Data de Criação:
                      </Typography>
                      <Typography>
                        {handleDateTime(notificationInfo?.registerDateTime)}
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Situação:
                      </Typography>
                      <Typography>Paga</Typography>
                    </StyledModalTextRow>
                    {notificationInfo?.observation && (
                      <>
                        <StyledModalTextRow>
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Observação:
                          </Typography>
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ textAlign: 'right' }}>
                            {notificationInfo?.observation}
                          </Typography>
                        </StyledModalTextRow>
                      </>
                    )}
                    <StyledModalTextRow>
                      <Typography
                        color='primary'
                        sx={{ fontWeight: 'bold', width: '100%' }}
                      >
                        Trecho:
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography sx={{ textAlign: 'right' }}>
                        {notificationInfo?.routeName}
                      </Typography>
                    </StyledModalTextRow>
                    <Box
                      sx={{
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <StyledModalTextRow>
                        <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                          Localização:
                        </Typography>
                        <a
                          href={handleMapLink(
                            notificationInfo?.latitude.toString(),
                            notificationInfo?.longitude.toString(),
                          )}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Typography>Ver no mapa</Typography>
                        </a>
                      </StyledModalTextRow>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <StyledModalTextRow>
                        <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                          Ordem de serviço:
                        </Typography>
                      </StyledModalTextRow>
                      <StyledModalTextRow>
                        <Typography sx={{ fontStyle: 'italic' }}>
                          Data de registro:{' '}
                        </Typography>{' '}
                        {notificationInfo?.serviceOrderDateTime &&
                          handleDateTime(notificationInfo.serviceOrderDateTime)}
                      </StyledModalTextRow>
                      <StyledModalTextRow>
                        <Typography sx={{ fontStyle: 'italic' }}>
                          Data de entrada:{' '}
                        </Typography>{' '}
                        {notificationInfo?.serviceOrderEntryDateTime &&
                          handleDateTime(
                            notificationInfo.serviceOrderEntryDateTime,
                          )}
                      </StyledModalTextRow>
                      <StyledModalTextRow>
                        <Typography sx={{ fontStyle: 'italic' }}>
                          Data de saída:{' '}
                        </Typography>{' '}
                        {notificationInfo?.serviceOrderExitDateTime &&
                          handleDateTime(
                            notificationInfo.serviceOrderExitDateTime,
                          )}
                      </StyledModalTextRow>
                    </Box>
                    {notificationInfo &&
                      (notificationInfo.plateImg ||
                        notificationInfo.locationImg) && (
                        <Box
                          sx={{
                            width: '100%',
                            height: 450,
                            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                            marginTop: '10px',
                            paddingTop: '10px',
                          }}
                        >
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Imagens
                          </Typography>
                          <ImageList
                            color='primary'
                            sx={{
                              fontWeight: 'bold',
                              marginTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            {notificationInfo.plateImg && (
                              <ImageListItem>
                                <img
                                  src={notificationInfo.plateImg}
                                  srcSet={notificationInfo.plateImg}
                                  alt={'Imagem da Placa'}
                                  loading='lazy'
                                />
                              </ImageListItem>
                            )}
                            {notificationInfo.locationImg && (
                              <ImageListItem>
                                <img
                                  src={notificationInfo.locationImg}
                                  srcSet={notificationInfo.locationImg}
                                  alt={'Imagem do Local'}
                                  loading='lazy'
                                />
                              </ImageListItem>
                            )}
                          </ImageList>
                        </Box>
                      )}
                  </StyledModalInnerWrapper>
                </StyledModalWrapper>
              </>
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '20px',
              }}
            >
              <LoadingButton
                variant='contained'
                startIcon={<DownloadIcon />}
                color='primary'
                onClick={() => handleExportHTML()}
                loading={notificationDetailsExportHTMLLoading}
                sx={{ width: '100%', margin: 0.5 }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                  }}
                  noWrap
                >
                  Baixar detalhamento
                </Typography>
              </LoadingButton>
            </Box>
          </>
        );
      case 'Cancelada':
        return (
          <>
            {notificationInfoLoading ? (
              <StyledModalWrapper>
                <StyledModalInnerWrapper>
                  {times(6, () => (
                    <Skeleton animation='wave' height={29} width='100%' />
                  ))}
                  <Skeleton animation='wave' height={100} width='100%' />
                  <Skeleton animation='wave' height={39} width='100%' />
                  <Skeleton animation='wave' height={200} width='100%' />
                </StyledModalInnerWrapper>
              </StyledModalWrapper>
            ) : (
              <>
                <StyledModalWrapper>
                  <StyledModalInnerWrapper>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Usuário:
                      </Typography>
                      <Typography>{notificationInfo?.userName}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Veículo:
                      </Typography>
                      <Typography>{notificationInfo?.plate}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Data de Criação:
                      </Typography>
                      <Typography>
                        {handleDateTime(notificationInfo?.registerDateTime)}
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Situação:
                      </Typography>
                      <Typography>Cancelada</Typography>
                    </StyledModalTextRow>
                    {notificationInfo?.observation && (
                      <>
                        <StyledModalTextRow>
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Observação:
                          </Typography>
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ textAlign: 'right' }}>
                            {notificationInfo?.observation}
                          </Typography>
                        </StyledModalTextRow>
                      </>
                    )}
                    <StyledModalTextRow>
                      <Typography
                        color='primary'
                        sx={{ fontWeight: 'bold', width: '100%' }}
                      >
                        Trecho:
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography sx={{ textAlign: 'right' }}>
                        {notificationInfo?.routeName}
                      </Typography>
                    </StyledModalTextRow>
                    <Box
                      sx={{
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <StyledModalTextRow>
                        <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                          Localização:
                        </Typography>
                        <a
                          href={handleMapLink(
                            notificationInfo?.latitude.toString(),
                            notificationInfo?.longitude.toString(),
                          )}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Typography>Ver no mapa</Typography>
                        </a>
                      </StyledModalTextRow>
                    </Box>
                    {notificationInfo &&
                      (notificationInfo.plateImg ||
                        notificationInfo.locationImg) && (
                        <Box
                          sx={{
                            width: '100%',
                            height: 450,
                            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                            marginTop: '10px',
                            paddingTop: '10px',
                          }}
                        >
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Imagens
                          </Typography>
                          <ImageList
                            color='primary'
                            sx={{
                              fontWeight: 'bold',
                              marginTop: '10px',
                              paddingBottom: '10px',
                            }}
                          >
                            {notificationInfo.plateImg && (
                              <ImageListItem>
                                <img
                                  src={notificationInfo.plateImg}
                                  srcSet={notificationInfo.plateImg}
                                  alt={'Imagem da Placa'}
                                  loading='lazy'
                                />
                              </ImageListItem>
                            )}
                            {notificationInfo.locationImg && (
                              <ImageListItem>
                                <img
                                  src={notificationInfo.locationImg}
                                  srcSet={notificationInfo.locationImg}
                                  alt={'Imagem do Local'}
                                  loading='lazy'
                                />
                              </ImageListItem>
                            )}
                          </ImageList>
                        </Box>
                      )}
                  </StyledModalInnerWrapper>
                </StyledModalWrapper>
              </>
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '20px',
              }}
            >
              <LoadingButton
                variant='contained'
                startIcon={<DownloadIcon />}
                color='primary'
                onClick={() => handleExportHTML()}
                loading={notificationDetailsExportHTMLLoading}
                sx={{ width: '100%', margin: 0.5 }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                  }}
                  noWrap
                >
                  Baixar detalhamento
                </Typography>
              </LoadingButton>
            </Box>
          </>
        );
      default:
        return (
          <>
            {notificationInfoLoading ? (
              <StyledModalWrapper>
                <StyledModalInnerWrapper>
                  {times(6, () => (
                    <Skeleton animation='wave' height={29} width='100%' />
                  ))}
                  <Skeleton animation='wave' height={100} width='100%' />
                  {times(4, () => (
                    <Skeleton animation='wave' height={29} width='100%' />
                  ))}
                </StyledModalInnerWrapper>
              </StyledModalWrapper>
            ) : (
              <>
                <StyledModalWrapper>
                  <StyledModalInnerWrapper>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Usuário:
                      </Typography>
                      <Typography>{notificationInfo?.userName}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Veículo:
                      </Typography>
                      <Typography>{notificationInfo?.plate}</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Data de Criação:
                      </Typography>
                      <Typography>
                        {handleDateTime(notificationInfo?.registerDateTime)}
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                        Situação:
                      </Typography>
                      <Typography>Regular</Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography
                        color='primary'
                        sx={{ fontWeight: 'bold', width: '100%' }}
                      >
                        Trecho:
                      </Typography>
                    </StyledModalTextRow>
                    <StyledModalTextRow>
                      <Typography sx={{ textAlign: 'right' }}>
                        {notificationInfo?.routeName}
                      </Typography>
                    </StyledModalTextRow>
                    <Box
                      sx={{
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }}
                    >
                      <StyledModalTextRow>
                        <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                          Localização:
                        </Typography>
                        <a
                          href={handleMapLink(
                            notificationInfo?.latitude.toString(),
                            notificationInfo?.longitude.toString(),
                          )}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Typography>Ver no mapa</Typography>
                        </a>
                      </StyledModalTextRow>
                    </Box>
                    {notificationInfo?.serviceOrderId && (
                      <Box
                        sx={{
                          width: '100%',
                          borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                          marginTop: '10px',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                        }}
                      >
                        <StyledModalTextRow>
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Ordem de serviço:
                          </Typography>
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ fontStyle: 'italic' }}>
                            Data de registro:{' '}
                          </Typography>{' '}
                          {notificationInfo?.serviceOrderDateTime &&
                            handleDateTime(
                              notificationInfo.serviceOrderDateTime,
                            )}
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ fontStyle: 'italic' }}>
                            Data de entrada:{' '}
                          </Typography>{' '}
                          {notificationInfo?.serviceOrderEntryDateTime &&
                            handleDateTime(
                              notificationInfo.serviceOrderEntryDateTime,
                            )}
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ fontStyle: 'italic' }}>
                            Data de saída:{' '}
                          </Typography>{' '}
                          {notificationInfo?.serviceOrderExitDateTime &&
                            handleDateTime(
                              notificationInfo.serviceOrderExitDateTime,
                            )}
                        </StyledModalTextRow>
                      </Box>
                    )}
                    {notificationInfo?.credentialId && (
                      <Box
                        sx={{
                          width: '100%',
                          borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                          marginTop: '10px',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                        }}
                      >
                        <StyledModalTextRow>
                          <Typography
                            color='primary'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Credencial:
                          </Typography>
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ fontStyle: 'italic' }}>
                            Número da credencial:{' '}
                          </Typography>{' '}
                          {notificationInfo?.credentialId}
                        </StyledModalTextRow>
                        <StyledModalTextRow>
                          <Typography sx={{ fontStyle: 'italic' }}>
                            Nome do credenciado:{' '}
                          </Typography>{' '}
                          {notificationInfo?.credentialUserName}
                        </StyledModalTextRow>
                      </Box>
                    )}
                  </StyledModalInnerWrapper>
                </StyledModalWrapper>
              </>
            )}
          </>
        );
    }
  };

  const handleEditNotification = () => {
    if (notificationInfo) {
      dispatch(
        notificationSlice.actions.setNotificationEditVehicleTypeId({
          notificationId: notificationInfo.id,
        }),
      );
      dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
      dispatch(
        notificationSlice.actions.toggleNotificationEditVehicleTypeIsOpen(),
      );
    }
  };

  const handleEditRegisters = () => {
    if (notificationInfo) {
      dispatch(
        fetchNotificationInfoForEditRecords({
          notificationId: notificationInfo.id,
        }),
      ).then((res: any) => {
        if (res.payload.data.records.length > 0) {
          dispatch(
            notificationSlice.actions.setNotificationEditRecordsId({
              notificationId: notificationInfo.id,
            }),
          );
          dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
          navigate(NOTIFICATIONS_EDIT_RECORDS);
        } else {
          toast.error('Essa notificação não possui registros.');
        }
      });
    }
  };

  const handleEditPrice = () => {
    if (notificationInfo) {
      dispatch(
        notificationSlice.actions.setNotificationEditPriceId({
          notificationId: notificationInfo.id,
        }),
      );
      dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
      dispatch(notificationSlice.actions.toggleNotificationEditPriceIsOpen());
    }
  };

  return (
    <Modal
      open={notificationInfoIsOpen}
      onClose={() =>
        dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen())
      }
      title={`Detalhamento`}
    >
      {getNotificationModalBody()}
    </Modal>
  );
};
