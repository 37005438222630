/* eslint-disable pii/no-phone-number */
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Grid, IconButton, Typography } from '@mui/material';
import { omit } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Dropdown,
  DatePicker,
  TimePicker,
  PageWrapper,
  FiltersGrid,
  Accordion,
  DropdownInput,
  Switch,
} from '../../components';
import {
  fetchSalesFilters,
  useDispatch,
  useSelector,
  salesFiltersValuesSlice,
  fetchSalesList,
  salesListSlice,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { daysOptions } from './constants';
import {
  SaleEditPaymentModal,
  SaleInfoModal,
  SaleOperationalExportModal,
  SaleSendEmailModal,
  SalesExportModal,
} from './modals';
import { StyledFiltersBtnsGrid } from './styles';
import { VehiclesEntryTable } from './table';

export const SalesManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { filters, filtersLoading } = useSelector(
    (state) => state.salesFiltersReducer,
  );
  const filtersValues = useSelector((state) => state.salesFiltersValuesReducer);
  const { salesPage, salesListLoading } = useSelector(
    (state) => state.salesListReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [expandedAccordeon, setExpandedAccordeon] = useState(false);
  const [formData, setFormData] = useState({
    days: filtersValues.days,
    startDate: moment(filtersValues.startDateTime).format('yyyy-MM-DD'),
    startTime: moment(filtersValues.startDateTime).format('HH:mm'),
    finalDate: moment(filtersValues.finalDateTime).format('yyyy-MM-DD'),
    finalTime: moment(filtersValues.finalDateTime).format('HH:mm'),
    plate: filtersValues.plate,
    userName: filtersValues.userName,
    serviceOrderUserId: filtersValues.serviceOrderUserId,
    driverId: filtersValues.driverId,
    serviceOrderOrigin: filtersValues.serviceOrderOrigin,
    paymentMethod: filtersValues.paymentMethod,
    routeAreaId: filtersValues.routeAreaId,
    routeId: filtersValues.routeId,
    reprint: filtersValues.reprint === '1' ? true : false,
    hasNotPlate: filtersValues.hasNotPlate === '1' ? true : false,
    hasIrregularity: filtersValues.hasIrregularity === '1' ? true : false,
    hasDiscount: filtersValues.hasDiscount === '1' ? true : false,
    profileId: filtersValues.profileId,
    posMachine: filtersValues.posMachine,
  });

  useEffect(() => {
    const values = {
      ...omit(filtersValues, ['days']),
      page: salesPage.toString(),
    };
    dispatch(fetchSalesFilters());
    dispatch(fetchSalesList(values));
  }, []);

  useEffect(() => {
    const values = {
      days: formData.days,
      startDateTime: moment(formData.startDate + ' ' + formData.startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      finalDateTime: moment(
        formData.finalDate + ' ' + formData.finalTime + ':59',
      )
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      plate: formData.plate,
      userName: formData.userName,
      serviceOrderUserId: formData.serviceOrderUserId,
      driverId: formData.driverId,
      serviceOrderOrigin: formData.serviceOrderOrigin,
      paymentMethod:
        formData.paymentMethod === '' ? 'Todos' : formData.paymentMethod,
      routeAreaId: formData.routeAreaId,
      routeId: formData.routeId,
      reprint: formData.reprint === true ? '1' : '0',
      hasNotPlate: formData.hasNotPlate === true ? '1' : '0',
      hasIrregularity: formData.hasIrregularity === true ? '1' : '0',
      hasDiscount: formData.hasDiscount === true ? '1' : '0',
      profileId: formData.profileId,
      posMachine: formData.posMachine,
      page: salesPage,
    };

    dispatch(salesFiltersValuesSlice.actions.setSalesFiltersValues(values));
  }, [formData]);

  const handleSubmit = () => {
    const values = {
      ...filtersValues,
      page: '1',
    };

    dispatch(salesFiltersValuesSlice.actions.setSalesFiltersValues(values));
    dispatch(salesListSlice.actions.setSalesPage(1));
    dispatch(fetchSalesList(omit(values, ['days'])));
    setExpandedAccordeon(false);
  };

  const handleReset = () => {
    const startDate = moment().format('yyyy-MM-DD');
    const startTime = moment().format('00:00');
    const finalDate = moment().format('yyyy-MM-DD');
    const finalTime = moment().format('23:59');
    const values = {
      days: '0',
      startDateTime: moment(startDate + ' ' + startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      finalDateTime: moment(finalDate + ' ' + finalTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      plate: '',
      userName: '',
      serviceOrderUserId: '0',
      driverId: '0',
      serviceOrderOrigin: '0',
      paymentMethod: '0',
      routeAreaId: '0',
      routeId: '0',
      reprint: '0',
      hasNotPlate: '0',
      hasIrregularity: '0',
      hasDiscount: '0',
      profileId: '0',
      posMachine: '0',
      page: '1',
    };

    setFormData({
      days: '0',
      startDate: moment().format('yyyy-MM-DD'),
      startTime: moment().format('00:00'),
      finalDate: moment().format('yyyy-MM-DD'),
      finalTime: moment().format('23:59'),
      plate: '',
      userName: '',
      serviceOrderUserId: '0',
      driverId: '0',
      serviceOrderOrigin: '0',
      paymentMethod: '0',
      routeAreaId: '0',
      routeId: '0',
      reprint: false,
      hasNotPlate: false,
      hasIrregularity: false,
      hasDiscount: false,
      profileId: '0',
      posMachine: '0',
    });
    dispatch(salesFiltersValuesSlice.actions.setSalesFiltersValues(values));
    dispatch(fetchSalesList(omit(values, ['days'])));
  };

  const handleSelectCustomizedDays = (value: string, field: string) => {
    if (field === 'startTime' || field === 'finalTime') {
      setFormData({
        ...formData,
        [field]: value,
      });
    } else {
      let daysValue = '1000';

      const startDateTime = moment(
        `${field === 'startDate' ? value : formData.startDate}`,
        'yyyy-MM-DD',
      );
      const finalDateTime = moment(
        `${field === 'finalDate' ? value : formData.finalDate}`,
        'yyyy-MM-DD',
      );
      const diff = moment(finalDateTime).diff(startDateTime);

      if (diff === 0 && value === moment().format('yyyy-MM-DD').toString()) {
        daysValue = '0';
      } else if (diff === 1296000000) {
        daysValue = '15';
      } else if (diff === 2592000000) {
        daysValue = '30';
      } else if (diff === 5184000000) {
        daysValue = '60';
      } else if (diff === 7776000000) {
        daysValue = '90';
      }

      setFormData({
        ...formData,
        days: daysValue,
        [field]: value,
      });
    }
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <ReceiptIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciar rua
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <FiltersGrid loading={!filters && filtersLoading} loadingRows={3}>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Filtro de dias'
                  options={daysOptions}
                  value={formData.days}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      days: value,
                      startDate: moment()
                        .subtract(parseInt(value), 'days')
                        .format('yyyy-MM-DD'),
                      startTime: moment().format('00:00'),
                      finalDate: moment().format('yyyy-MM-DD'),
                      finalTime: moment().format('23:59'),
                    });
                  }}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data inicial'
                  value={formData.startDate}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'startDate')
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora inicial'
                  value={formData.startTime}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'startTime')
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data final'
                  value={formData.finalDate}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'finalDate')
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora final'
                  value={formData.finalTime}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'finalTime')
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='plate'
                  type='text'
                  margin='normal'
                  label='Placa'
                  value={formData.plate}
                  onChange={(value) =>
                    setFormData({ ...formData, plate: value })
                  }
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Usuário'
                  value={formData.serviceOrderUserId}
                  options={filters && filters.users}
                  onChange={(value) =>
                    setFormData({ ...formData, serviceOrderUserId: value })
                  }
                />
              </Grid>
              {/* <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Motorista'
                  value={formData.driverId}
                  options={filters && filters.drivers}
                  onChange={(value) =>
                    setFormData({ ...formData, driverId: value })
                  }
                />
              </Grid> */}
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Origem'
                  value={formData.serviceOrderOrigin}
                  options={filters && filters.serviceOrderOrigins}
                  onChange={(value) =>
                    setFormData({ ...formData, serviceOrderOrigin: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Pagamento'
                  value={formData.paymentMethod}
                  options={filters && filters.paymentMethods}
                  onChange={(value) => {
                    setFormData({ ...formData, paymentMethod: value });
                  }}
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Área'
                  value={formData.routeAreaId}
                  options={filters && filters.areas}
                  onChange={(value) => {
                    setFormData({ ...formData, routeAreaId: value });
                  }}
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Trecho'
                  value={formData.routeId}
                  options={filters && filters.routes}
                  onChange={(value) =>
                    setFormData({ ...formData, routeId: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Perfil'
                  value={formData.profileId}
                  options={filters && filters.profiles}
                  onChange={(value) =>
                    setFormData({ ...formData, profileId: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Máquina'
                  value={formData.posMachine}
                  options={filters && filters.posMachines}
                  onChange={(value) =>
                    setFormData({ ...formData, posMachine: value })
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Switch
                  label='Desconto'
                  checked={formData.hasDiscount}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      hasDiscount: !formData.hasDiscount,
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Switch
                  label='Reimpressão'
                  checked={formData.reprint}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      reprint: !formData.reprint,
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Switch
                  label='Sem Placa'
                  checked={formData.hasNotPlate}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      hasNotPlate: !formData.hasNotPlate,
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Switch
                  label='Irregularidade'
                  checked={formData.hasIrregularity}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      hasIrregularity: !formData.hasIrregularity,
                    })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={1.5}
                md={4}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  disabled={salesListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={salesListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </Grid>
          </FiltersGrid>
        </Grid>
      ) : (
        <Accordion
          expanded={expandedAccordeon}
          setExpanded={setExpandedAccordeon}
          title='Filtros'
        >
          <FiltersGrid loading={filtersLoading} loadingRows={3}>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Filtro de dias'
                options={daysOptions}
                value={formData.days}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    days: value,
                    startDate: moment()
                      .subtract(parseInt(value), 'days')
                      .format('yyyy-MM-DD'),
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data inicial'
                value={formData.startDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    startDate: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora inicial'
                value={formData.startTime}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    startTime: value,
                  });
                }}
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data final'
                value={formData.finalDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    finalDate: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora final'
                value={formData.finalTime}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    finalTime: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='plate'
                type='text'
                margin='normal'
                label='Placa'
                value={formData.plate}
                onChange={(value) => setFormData({ ...formData, plate: value })}
              />
            </Grid>
            {/* <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='userName'
                type='text'
                margin='normal'
                label='Usuário'
                value={formData.userName}
                onChange={(value) =>
                  setFormData({ ...formData, userName: value })
                }
              />
            </Grid> */}
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Usuário'
                value={formData.serviceOrderUserId}
                options={filters && filters.users}
                onChange={(value) =>
                  setFormData({ ...formData, serviceOrderUserId: value })
                }
              />
            </Grid>
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Motorista'
                value={formData.driverId}
                options={filters && filters.drivers}
                onChange={(value) =>
                  setFormData({ ...formData, driverId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Origem'
                value={formData.serviceOrderOrigin}
                options={filters && filters.serviceOrderOrigins}
                onChange={(value) =>
                  setFormData({ ...formData, serviceOrderOrigin: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Pagamento'
                value={formData.paymentMethod}
                options={filters && filters.paymentMethods}
                onChange={(value) =>
                  setFormData({ ...formData, paymentMethod: value })
                }
              />
            </Grid>
            {/* <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Área'
                value={formData.routeAreaId}
                options={filters && filters.areas}
                onChange={(value) => {
                  setFormData({ ...formData, routeAreaId: value });
                }}
              />
            </Grid> */}
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Trecho'
                value={formData.routeId}
                options={filters && filters.routes}
                onChange={(value) =>
                  setFormData({ ...formData, routeId: value })
                }
              />
            </Grid>
            {/* <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Perfil'
                value={formData.profileId}
                options={filters && filters.profiles}
                onChange={(value) =>
                  setFormData({ ...formData, profileId: value })
                }
              />
            </Grid> */}
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Máquina'
                value={formData.posMachine}
                options={filters && filters.posMachines}
                onChange={(value) =>
                  setFormData({ ...formData, posMachine: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Switch
                label='Desconto'
                checked={formData.hasDiscount}
                onChange={() =>
                  setFormData({
                    ...formData,
                    hasDiscount: !formData.hasDiscount,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Switch
                label='Reimpressão'
                checked={formData.reprint}
                onChange={() =>
                  setFormData({
                    ...formData,
                    reprint: !formData.reprint,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Switch
                label='Sem Placa'
                checked={formData.hasNotPlate}
                onChange={() =>
                  setFormData({
                    ...formData,
                    hasNotPlate: !formData.hasNotPlate,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Switch
                label='Irregularidade'
                checked={formData.hasIrregularity}
                onChange={() =>
                  setFormData({
                    ...formData,
                    hasIrregularity: !formData.hasIrregularity,
                  })
                }
              />
            </Grid>
            <StyledFiltersBtnsGrid
              item
              lg={1.5}
              md={4}
              sm={4}
              xs={12}
              margin={1}
            >
              <Button
                variant='contained'
                label='Filtrar'
                startIcon={<FilterAltIcon />}
                sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
                onClick={handleSubmit}
                disabled={salesListLoading}
              />
              <IconButton
                color='secondary'
                aria-label='Remover filtros'
                onClick={handleReset}
                disabled={salesListLoading}
              >
                <CancelIcon fontSize='large' />
              </IconButton>
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        </Accordion>
      )}
      <Grid container margin={3}>
        <VehiclesEntryTable />
      </Grid>
      <SaleInfoModal />
      <SaleSendEmailModal />
      <SaleEditPaymentModal />
      <SaleOperationalExportModal />
      <SalesExportModal />
    </PageWrapper>
  );
};
